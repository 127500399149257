import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./GeneratedClientBill.module.scss";
import {
    EditOutlined,
    ExportOutlined,
    FilterOutlined,
    DeleteOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Tooltip, Popconfirm, Drawer, Form, Input, DatePicker, notification, Spin, Pagination } from "antd";
import CBTripCount from "../../../../assets/images/BillsIcons/CBTripCount.svg";
import CBbillNoCount from "../../../../assets/images/BillsIcons/billnocount.svg";
import CBLastDate from "../../../../assets/images/BillsIcons/CBlastdate.svg";
import CBNetWeight from "../../../../assets/images/BillsIcons/CBnetweight.svg";
import { Link } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

export const GeneratedClientBill = () => {
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE];
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [billNoCount, setBillNoCount] = useState(0)
    const [startUnLoadingDate, setStartUnLoadingDate] = useState(0)
    const [lastUnLoadingDate, setLastUnLoadingDate] = useState(0)
    //const [totalNetWeight, setTotalNetWeight] = useState(0)
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [filterForm] = Form.useForm()
    const [generatedBills, setGeneratedBills] = useState([])
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
    }

    const filterResults = (formValues) => {
        formValues.date_of_billing = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        loadGeneratedBills(currentPage, pageSize, true, formValues)
        setCurrentSetFilterValues(formValues)
        setOpen(false);
    }

    const findAgencyRateType = (rateType) => {
        return constantsArr.AGENCY_RATE_TYPES?.find((item) => item?.value === rateType)?.label ?? null
    }

    const exportLoadingGeneratedBills = async (billId) => {
        const exportResponse = await AppHelper.getClientBillDetails(billId)
        if (exportResponse?.error) {
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Loading Agency', key: "Loading_Agency", width: 20 },
                { header: 'Loading Agency Rate', key: "Loading_Agency_Rate", width: 20 },
                { header: 'Loading Agency Rate Type', key: "Loading_Agency_Rate_Type", width: 25 },
                { header: 'Date of UL', key: "Date_of_Unloading", width: 20 },
                { header: 'Unloading Agency', key: "Unloading_Agency", width: 20 },
                { header: 'Unloading Agency Rate', key: "Unloading_Agency_Rate", width: 20 },
                { header: 'Unloading Agency Rate Type', key: "Unloading_Agency_Rate_Type", width: 25 },
                { header: 'Vehicle Number.', key: "Vehicle_Number", width: 15 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'HSD (LTR) Rate', key: "HSD_LTR_Rate", width: 15 },
                { header: 'Transporter Agency', key: "Transporter_Agency", width: 20 },
                { header: 'Transporter Agency Rate', key: "Transporter_Agency_Rate", width: 20 },
                { header: 'Transporter Agency Rate Type', key: "Transporter_Agency_Rate_Type", width: 25 },
                { header: 'Thermal Plant Book No', key: "Thermal_Plant_Book_no", width: 20 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 20 },
                { header: 'Gross weight (MT)', key: "Gross_Weight", width: 20 },
                { header: 'Tare weight (MT)', key: "Tare_Weight", width: 15 },
                { header: 'Kanta Slip No. UL', key: "Kanta_Slip_No", width: 25 },
                { header: 'Unloading Location', key: "Unloading_Location", width: 20 },
                { header: 'Loading Work Amount', key: "Loading_Work_Amount", width: 20 },
                { header: 'Unloading Work Amount', key: "Unloading_Work_Amount", width: 20 },
                { header: 'Transportation Work Amount', key: "Transportation_Work_Amount", width: 20 },
                { header: 'HSD Amount', key: "HSD_AMOUNT", width: 20 },
            ]

            exportResponse?.loading_ids.forEach((billData, key) => {

                const loadingAgencyRateType = findAgencyRateType(billData?.loading?.Loading_Rate_Type)
                const unloadingAgencyRateType = findAgencyRateType(billData?.loading?.Unloading?.Unloading_Rate_Type)
                const transpotationAgencyRateType = findAgencyRateType(billData?.loading?.Transportation_Rate_Type)
                
                const unloadingNetWeight = parseFloat((billData?.loading?.Unloading?.Net_Weight / 1000))
                //const loadingNetWeight = parseFloat((billData?.loading?.Net_Weight / 1000))

                billData.loading.sl_no = key + 1
                billData.loading.Challan_Number = parseInt(billData?.loading?.Challan_Number)
                billData.loading.Unloading_Location = billData?.loading?.Unloading?.Unloading_Location
                billData.loading.Net_Weight = ((unloadingNetWeight && unloadingNetWeight !== "") ? parseFloat(unloadingNetWeight).toFixed(2) : 0)
                billData.loading.Kanta_Slip_No = billData?.loading?.Unloading?.Kanta_Slip_No
                billData.loading.Thermal_Plant_Book_no = billData?.loading?.Unloading?.Thermal_Plant_Book_no

                billData.loading.Transporter_Agency = billData?.loading?.Transporter_Agency?.Company_name
                billData.loading.Transporter_Agency_Rate = billData?.loading?.Transportation_Rate_Value ? parseFloat(billData?.loading?.Transportation_Rate_Value) : ""
                billData.loading.Transporter_Agency_Rate_Type = transpotationAgencyRateType

                billData.loading.Unloading_Agency = billData?.loading?.Unloading?.Unloading_Agency?.Company_name
                billData.loading.Unloading_Agency_Rate = billData?.loading?.Unloading?.Unloading_Rate_Value ? parseFloat(billData?.loading?.Unloading?.Unloading_Rate_Value) : ""
                billData.loading.Unloading_Agency_Rate_Type = unloadingAgencyRateType

                billData.loading.HSD_LTR = billData?.loading?.hsd_detail?.HSD_LTR ? parseFloat(billData?.loading?.hsd_detail?.HSD_LTR.toFixed(2)) : ""
                billData.loading.HSD_LTR_Rate = billData?.loading?.hsd_detail?.Rate_INR ? parseFloat(billData?.loading?.hsd_detail?.Rate_INR) : ""
                billData.loading.Tare_Weight = ((billData?.loading?.Unloading?.Tare_Weight && billData?.loading?.Unloading?.Tare_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Tare_Weight / 1000).toFixed(2)) : 0)
                billData.loading.Gross_Weight = ((billData?.loading?.Unloading?.Gross_Weight && billData?.loading?.Unloading?.Gross_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Gross_Weight / 1000).toFixed(2)) : 0)
                billData.loading.Vehicle_Number = billData?.loading?.Vehicle_Number?.Vehicle_Number

                billData.loading.Loading_Agency = billData?.loading?.Loading_Agency?.Company_name
                billData.loading.Loading_Agency_Rate = billData?.loading?.Loading_Rate_Value ? parseFloat(billData?.loading?.Loading_Rate_Value) : ""
                billData.loading.Loading_Agency_Rate_Type = loadingAgencyRateType

                billData.loading.Date_of_Unloading = dayjs(billData?.loading?.Unloading?.Date_of_Unloading).format(dateFormat)
                billData.loading.Date_of_Loading = dayjs(billData?.loading?.Date_of_Loading).format(dateFormat)

                let loadingWorkAmount = 0
                let unloadingWorkAmount = 0
                let transportationWorkAmount = 0

                if (loadingAgencyRateType === 'Per (MT)')
                    loadingWorkAmount = parseFloat((unloadingNetWeight * billData?.loading?.Loading_Rate_Value).toFixed(2))
                else if (loadingAgencyRateType === 'Per (Unit)')
                    loadingWorkAmount = parseFloat(billData?.loading?.Loading_Rate_Value)
                else
                    loadingWorkAmount = 0

                if (unloadingAgencyRateType === 'Per (MT)')
                    unloadingWorkAmount = parseFloat((unloadingNetWeight * billData?.loading?.Unloading?.Unloading_Rate_Value).toFixed(2))
                else if (unloadingAgencyRateType === 'Per (Unit)')
                    unloadingWorkAmount = billData?.loading?.Unloading?.Unloading_Rate_Value
                else
                    unloadingWorkAmount = 0

                if (transpotationAgencyRateType === 'Per (MT)')
                    transportationWorkAmount = parseFloat((unloadingNetWeight * billData?.loading?.Transportation_Rate_Value).toFixed(2))
                else if (transpotationAgencyRateType === 'Per (Unit)')
                    transportationWorkAmount = billData?.loading?.Transportation_Rate_Value
                else
                    transportationWorkAmount = 0

                billData.loading.Loading_Work_Amount = loadingWorkAmount
                billData.loading.Unloading_Work_Amount = unloadingWorkAmount
                billData.loading.Transportation_Work_Amount = transportationWorkAmount
                billData.loading.HSD_AMOUNT = billData?.loading?.hsd_detail?.HSD_Amt ? parseFloat(billData?.loading?.hsd_detail?.HSD_Amt) : 0

                workSheetPendingBills.addRow(billData?.loading)
            })

            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Transaction Summary")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.Project?.Thermal_Plant?.Name, key: "second_value", width: 25 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.Project?.Name_of_project ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Bill Date", second_value: exportResponse?.bill_date ? dayjs(exportResponse?.bill_date).format(dateFormat) : '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Bill No.", second_value: exportResponse?.bill_no ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Trip Count", second_value: exportResponse?.trip_count ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Starting Date (UL)", second_value: exportResponse?.unloading_starting_date ? dayjs(exportResponse?.unloading_starting_date).format(dateFormat) : '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "End Date (UL)", second_value: exportResponse?.unloading_end_date ? dayjs(exportResponse?.unloading_end_date).format(dateFormat) : '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Net Weight (UL)", second_value: exportResponse?.total_net_weight ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "CUM", second_value: exportResponse?.cum ?? 0 }
            )


            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `client_generated_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Client generated bills data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
        }
    }

    const handleDeleteGeneratedClientBill = (billId) => {
        AppHelper.deleteClientBillGenerated(billId).then(response => {
            notification.success({
                message: "Client bill deleted successfully",
                description: "",
                duration: 3
            })
            loadGeneratedBills(currentPage, pageSize, true)
        }).catch(err => {
            notification.error({
                message: "Unable to delete client bill",
                description: err?.message,
                duration: 3
            })
        })
    }

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1

        setCurrentPageSizePagination(pageSize);

        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadGeneratedBills(currentPage, pageSize, true, currentSetFilterValues);
    };

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
        }
    }

    const setGlobalCountItems = (response) => {
        setTotalTripCount(response?.meta?.tripCount ?? 0)
        setBillNoCount(response?.meta?.totalLoading ?? 0)
        setStartUnLoadingDate(response?.meta?.minUnloadingDate && response?.meta?.minUnloadingDate !== "-" ? dayjs(response?.meta?.minUnloadingDate).format(dateFormat) : '-')
        setLastUnLoadingDate(response?.meta?.maxUnloadingDate && response?.meta?.maxUnloadingDate !== "-" ? dayjs(response?.meta?.maxUnloadingDate).format(dateFormat) : '-')
        //setTotalNetWeight(response?.meta?.totalNetWeight ?? 0)
    }

    const loadGeneratedBills = (currentPage, pageSize, pagination, filterArgs) => {
        setShowTableSpinner(true)
        AppHelper.getGeneratedClientBills(currentPage, pageSize, pagination, filterArgs).then(response => {
            setGlobalCountItems(response)
            setGeneratedBills(response)
            setShowTableSpinner(false)
            setShowPagination(true);
        }).catch(err => {
            notification.error({
                message: "Unable to load generated client bills",
                description: "",
                duration: 3
            })
        })
    }

    useEffect(() => {
        loadGeneratedBills(currentPage, pageSize, true)
        document.title = "Generated Client Bill"
    }, [])
    return (
        <>
            <Grid
                display="flex"
                justifyContent="space-between"
                className={classes["generated-bill-client"]}
            >
                <Grid>
                    <Typography variant="h5" color="initial">
                        Client billing
                    </Typography>
                </Grid>
                <Grid>
                    <UIButton
                        variant="outlined"
                        className={classes["filter-new-entry"]}
                        onClick={showDrawer}
                        startIcon={<FilterOutlined />}
                    >
                        Filters
                    </UIButton>
                    <Drawer title="Filters" onClose={onClose} open={open}>
                        <Form
                            layout="vertical"
                            form={filterForm}
                            onFinish={filterResults}
                        >
                            <Form.Item label="Bill No." name="bill_number">
                                <Input
                                    placeholder="Enter Bill No."
                                    addonBefore={<SearchOutlined />}
                                />
                            </Form.Item>
                            <Form.Item label="Date Billing" name="date_of_billing">
                                <RangePicker
                                    style={{ width: "100%" }}
                                    picker="date"
                                    onChange={handleRangePicker}
                                    format={dateFormat}
                                />
                            </Form.Item>
                            <div className={classes["search-button-area"]}>
                                <UIButton
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={resetFilterForm}
                                >
                                    Reset
                                </UIButton>
                                <UIButton
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes["submit-button"]}
                                >
                                    Apply
                                </UIButton>
                            </div>
                        </Form>
                    </Drawer>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-bill-client"]}
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    container
                    spacing={0}
                    className={classes["headerMarginTop"]}
                >
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBTripCount}
                                        alt="Trip Count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {totalTripCount}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Trip Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBbillNoCount}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {billNoCount}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Bill No. Counts
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBNetWeight}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {startUnLoadingDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Start Unloading Date
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBLastDate}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {lastUnLoadingDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        End Unloading date
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-bill-client"]}
            >
                <Grid
                    item
                    lg={9.8}
                    md={8.5}
                    sm={12}
                    xs={12}
                    container
                    className={classes["bill-number"]}
                    textAlign="center"
                >
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Bill No.
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Date of Billing
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            Start Unloading Date
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            End Unloading Date
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={3} xs={3}>
                        <Typography variant="body1" color="#353535" fontWeight="bold">
                            CUM
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item lg={2.2} md={3.5} sm={6} xs={12} container>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                    <Grid item lg={4} md={6} sm={4} xs={6}>
                        &nbsp;
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0.5}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generated-bill-client"]}
            >
                {showTableSpinner ? (
                    // Show spinner while data is being loaded
                    <Spin size="large" className={classes["generated-spin"]} />
                ) :
                    generatedBills?.data?.attributes?.results.length > 0
                        ?
                        generatedBills?.data?.attributes?.results?.map((item) => (
                            <>
                                <Grid
                                    // key={item?.id}
                                    item
                                    lg={9.8}
                                    md={8.5}
                                    sm={12}
                                    xs={12}
                                    container
                                    className={classes["bill-number-detail-background"]}
                                    textAlign="center"
                                >
                                    <Link
                                        className={classes["view-list-flex-link"]}
                                        to={`/bills/client-bill/view-summary-bill/${item?.id}`}
                                    >
                                        <Grid item lg={2} md={3} xs={3}>
                                            <Typography variant="body1" color="#555555" fontWeight="bold">
                                                {item?.bill_no}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={3}>
                                            <Typography variant="body1" color="#555555" fontWeight="bold">
                                                {dayjs(item?.bill_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={3}>
                                            <Typography variant="body1" color="#555555" fontWeight="bold">
                                                {dayjs(item?.unloading_starting_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={3} xs={3}>
                                            <Typography variant="body1" color="#555555" fontWeight="bold">
                                                {dayjs(item?.unloading_end_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={3}>
                                            <Typography variant="body1" color="#555555" fontWeight="bold">
                                                {item?.cum}
                                            </Typography>
                                        </Grid>
                                    </Link>
                                </Grid>
                                <Grid
                                    item
                                    lg={2.2}
                                    md={3.5}
                                    sm={6}
                                    xs={12}
                                    container
                                    marginBottom="23px"
                                    textAlign="right"
                                >
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <Tooltip title="Export">
                                            <Link className={classes["bill-number-icons"]} to="#" onClick={() => exportLoadingGeneratedBills(item?.id)}>
                                                <ExportOutlined style={{ color: "#555555" }} />
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <Tooltip title="Edit">
                                            <Link className={classes["bill-number-icons"]} to={`${`/bills/client-bill/edit/${item?.id}`}`}
                                            >
                                                <EditOutlined style={{ color: "#555555" }} />
                                            </Link>
                                        </Tooltip>
                                    </Grid>
                                    <Grid lg={4} md={4} sm={4} xs={4}>
                                        <Popconfirm
                                            title="Delete the Bill"
                                            description="Are you sure to delete this generated client bill?"
                                            onConfirm={() => handleDeleteGeneratedClientBill(item?.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Link className={classes["bill-number-icons"]}>
                                                <DeleteOutlined style={{ color: "#ff0000" }} />
                                            </Link>
                                        </Popconfirm>
                                    </Grid>
                                </Grid>
                            </>
                        ))
                        :
                        <Typography variant="body1" className={classes["no-generated-bill-margin-auto"]}>
                            No client bill is available
                        </Typography>
                }
            </Grid>
            <>
                {
                    showPagination === true && generatedBills?.data?.attributes?.results?.length > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${total} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={generatedBills?.data?.attributes?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>
        </>
    );
};
